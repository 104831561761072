import React, { useState, useEffect, useContext } from "react";
import { ModelContext } from "./context.js";
import mssIcon from "../assets/facebook-messenger-logo-png-44107.png";

function Header() {
    const context = useContext(ModelContext);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentMobileSlide, setCurrentMobileSlide] = useState(0);
    const totalSlides = 6; // Number of slides

    useEffect(() => {
        // Automatic slide change for desktop
        const slideInterval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % totalSlides);
        }, 5000);

        return () => clearInterval(slideInterval);
    }, []);

    useEffect(() => {
        // Automatic slide change for mobile
        const slideIntervalM = setInterval(() => {
            setCurrentMobileSlide((prev) => (prev + 1) % totalSlides);
        }, 5000);

        return () => clearInterval(slideIntervalM);
    }, []);

    return (
        <>
            {/* Desktop Slideshow */}
            <section id="slideShow" className="slideShow">
                <div className="slidershow middle">
                    <div className="slides">
                        {["HM King Banner 1 (1).jpg","1. Registration Open.jpg", "2. BA.jpg", "3. BTEC.jpg", "4. Local Diploma.jpg","555Training Diploma.jpg"].map(
                            (image, index) => (
                                <div
                                    key={index}
                                    className={`slide s${index + 1}`}
                                    style={{
                                        display: index === currentSlide ? "block" : "none",
                                    }}
                                >
                                   
                                    <img src={`../assets/${image}`} 
                                    style={{ width: "100vw", right: "0vw" }} 
                                    alt="" />
                                    <a target="_blank" className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>
                                        {context.headerData.nav.contactUs.text}
                                    </a>
                                </div>
                            )
                        )}
                    </div>

                    <div className="navigation">
                        {[...Array(totalSlides)].map((_, index) => (
                            <button
                                key={index}
                                className={`bar ${index === currentSlide ? "active" : ""}`}
                                onClick={() => setCurrentSlide(index)}
                            />
                        ))}
                    </div>
                </div>

                {/* <a target="_blank" rel="noopener noreferrer" className="chat" href="https://m.me/LTUC.JO">
                    <i className="fa fa-comment"></i>
                </a> */}
            </section>

            {/* Mobile Slideshow */}
            <section id="slideShow" className="mobile">
                <div className="slidershow middle">
                    <div className="slides">
                        {["HM King Banner Mobile.jpg","M1Registration Open.jpg", "M2BA.jpg", "M3BTEC.jpg", "M4Local Diploma.jpg","M555Training Diploma.jpg"].map(
                            (image, index) => (
                                <div
                                    key={index}
                                    className={`slide sM${index + 1}`}
                                    style={{
                                        display: index === currentMobileSlide ? "block" : "none",
                                    }}
                                >
                                    
                                    <img src={`../assets/${image}`} style={{ width: "100vw", top: "0vh", right: "0vw" }} alt="" />
                                    <a target="_blank" className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>
                                        {context.headerData.nav.contactUs.text}
                                    </a>
                                </div>
                            )
                        )}
                    </div>

                    <div className="navigation">
                        {[...Array(totalSlides)].map((_, index) => (
                            <button
                                key={index}
                                className={`barM ${index === currentMobileSlide ? "active" : ""}`}
                                onClick={() => setCurrentMobileSlide(index)}
                            />
                        ))}
                    </div>
                </div>

                {/* <a target="_blank" rel="noopener noreferrer" className="chat" href="https://m.me/LTUC.JO">
                    <i className="fa fa-comment"></i>
                </a> */}
            </section>
        </>
    );
}

export default Header;